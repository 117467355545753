@use "@wojo/ui/mixins" as *;

.card {
    padding: var(--g-spacing-lg);
    display: grid;
    gap: var(--g-spacing-md);

    @include breakpoint-sm {
        padding: var(--g-spacing-xxl) var(--g-spacing-xl);
    }

    @include breakpoint-lg {
        padding: var(--g-spacing-xxl);
        gap: var(--g-spacing-xl);
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
    }
}

.heading {
    text-align: center;
    align-content: center;

    @include breakpoint-lg {
        padding-bottom: 0;
        text-align: left;
    }
}

.form {
    display: grid;
    gap: var(--g-spacing-md);
    align-items: center;
    justify-content: center;
    grid-template-columns: minmax(auto, 288px);

    @include breakpoint-sm {
        grid-template-columns: 288px max-content;
    }
}

.submit {
    justify-self: center;

    @include breakpoint-sm {
        align-self: center;
    }
}
